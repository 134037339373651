import Acheivement from './components/Acheivement';
import './App.css';

function App() {
  return (
    <Acheivement/>
  );
}

export default App;
